import React from 'react'
import styled from 'styled-components'

import Root from '../Root'
import Header from '../Header'
import Footer from '../Footer'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 300,
  })
}

const Wrapper = styled.main``

const Layout = ({ children, pathname }) => (
  <Root>
    <Header pathname={pathname} />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </Root>
)

export default Layout
