import { css } from 'styled-components'
import { theme } from 'styled-tools'

const colorsByType = type => {
  if (!type) {
    return
  }
  const bg = type
  const text = ['primary', 'secondary', 'dark'].includes(type)
    ? 'white'
    : 'primaryText'
  const h2 = type === 'primary' ? 'secondary' : 'primary'
  const strong = ['primary', 'secondary', 'dark'].includes(type)
    ? 'white'
    : 'secondary'
  const h3 = ['secondary', 'dark'].includes(type) ? 'primary' : 'secondary'

  return css`
    background: ${theme(`colors.${bg}`)};
    color: ${theme(`colors.${text}`)};
    h1,
    h2 {
      color: ${theme(`colors.${h2}`)};
      strong {
        font-weight: 700;
        color: ${theme(`colors.${strong}`)};
      }
    }
    h3 {
      color: ${theme(`colors.${h3}`)};
    }
  `
}

export default colorsByType
