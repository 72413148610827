import { createGlobalStyle } from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { normalize, rem, shade } from 'polished'

export default createGlobalStyle`
  ${normalize()};

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  :root {
    font-family: ${theme('typography.fontFamily')};
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    color: ${theme('colors.primaryText')};
    background: ${theme('colors.darkGray')};
    overflow-x: hidden;
  }

  body {
    margin: 0;
    font-size: ${rem(16)};
    text-align: center;
    overflow-x: hidden;
    background: ${theme('colors.background')};
  }

  h1 {
    font-size: ${rem(32)};
    font-family: ${theme('typography.fontFamily')};
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 1;
    margin: 0;
    color: ${theme('colors.secondary')};
  }

  h2 {
    font-size: ${rem(32)};
    font-family: ${theme('typography.fontFamily')};
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 1;
    margin: 0;
    color: ${theme('colors.secondary')};
  }

  h3 {
    font-size: ${rem(24)};
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 1;
    margin: 0;
    margin-bottom: ${rem(14)};
  }

  h4 {
    font-size: ${rem(16)};
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 1;
    margin: 0;
    margin-bottom: ${rem(14)};
  }

  p {
    font-size: ${rem(16)};
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.015em;
    margin: ${rem(12)} 0;
  }

  address {
    font-style: normal;
  }

  figure {
    margin: 0;
  }

  figcaption {
    margin: 0;
  }

  a {
    cursor: pointer;
    font-weight: 400;
    color: ${theme('colors.primary')};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  button {
    border-radius: 1000px;
    font-family: ${theme('typography.fontFamily')};
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.9em 1.6em;
    cursor: pointer;
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
    font-size: ${rem(14)};
    margin: ${rem(16)} 0 ${rem(4)} ${rem(2)};
    @media (min-width: ${theme('breakpoints.sm')}) {
      margin-bottom: ${rem(6)};
    }
  }

  input {

    display: block;
    width: 100%;
    text-align: left;
    padding: ${rem(8)};
    border: 1px solid ${withProp('theme.colors.background', shade(0.07))};
    border-radius: ${rem(2)};
    box-shadow: none;

    &[type='text'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  textarea {
    display: block;
    width: 100%;
    text-align: left;
    padding: ${rem(8)};
    border: 1px solid ${withProp('theme.colors.background', shade(0.07))};
    border-radius: ${rem(2)};
    box-shadow: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  ul {
    padding-left: ${rem(20)};
    line-height: 1.2;
    letter-spacing: 0.015em;
    li {
      margin-bottom: ${rem(4)};
    }
  }

  blockquote {
    line-height: 1.5;
  }
`
