import { css } from 'styled-components'
import { theme } from 'styled-tools'

export { default as theme } from './theme'
export { default as GlobalStyle } from './GlobalStyle'

export const media = breakpoint => (...args) => css`
  @media (min-width: ${theme(`breakpoints.${breakpoint}`)}) {
    ${css(...args)}
  }
`
