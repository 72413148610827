import React, { useRef, useEffect } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { rem } from 'polished'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import CrossCountry from '../Header/CrossCountry'
import { media } from '../../theme'
import InternalLink from '../BlockContent/serializers/InternalLink'

const locale = process.env.GATSBY_LOCALE

const Wrapper = styled.ul`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;

  transform: ${ifProp('open', 'translateX(0)', 'translateX(100%)')};
  transition: 150ms linear transform;

  background: ${theme('colors.white')};
  color: ${theme('colors.primaryText')};
  list-style: none;
  padding: 0;
  margin-top: ${rem(90)};
  text-align: left;
  li {
    padding: ${rem(16)} ${rem(12)};
    margin-bottom: 0;
    border-bottom: 1px solid ${theme('colors.background')};
    &:first-child {
      border-top: 1px solid ${theme('colors.background')};
    }
    ${media('sm')`
      &, &:first-child {
        border: none;
      }
    `}

    a {
      font-weight: 300;
      color: inherit;
      &:hover {
        text-decoration: none;
        color: ${theme('colors.primary')};
      }
    }
  }

  button {
    font-size: 80%;
  }

  ${media('sm')`
    margin-top: ${rem(120)};
  `}

  ${media('sm')`
    transform: none;
    background: transparent;
    position: absolute;
    left: initial;
    right: ${rem(24)};
    height: ${rem(60)};
    width: auto;
    margin-top: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${theme('colors.background')};
  `}
`

const HomeLink = styled.li`
  ${media('sm')`
    display: none;
  `}
`

const StyledCrossCountry = styled(CrossCountry)`
  display: block;
  padding: ${rem(28)};
  ${media('sm')`
    display: none;
  `}
`

const query = graphql`
  {
    sanityHeader {
      menuItems {
        _id
        title {
          enUS
          svSE
        }
      }
    }
  }
`

const Menu = ({ open, pathname }) => {
  const target = useRef()
  useEffect(() => {
    if (open) {
      disableBodyScroll(target.current)
    } else {
      enableBodyScroll(target.current)
    }
    return () => clearAllBodyScrollLocks()
  })
  const data = useStaticQuery(query)
  return (
    <Wrapper open={open} ref={target}>
      {pathname !== '/' && (
        <HomeLink>
          <Link to="/">{{ svSE: 'Hem', enUS: 'Home' }[locale]}</Link>
        </HomeLink>
      )}
      {data.sanityHeader.menuItems.map(item => (
        <li key={item._id}>
          <InternalLink to={item._id}>{item.title[locale]}</InternalLink>
        </li>
      ))}
      <StyledCrossCountry />
    </Wrapper>
  )
}

export default Menu
