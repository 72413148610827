import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'
import { Link } from 'gatsby'

import { media } from '../../theme'
import Logo from '../Logo'
import Block from '../Block'
import Social from '../Logo/Social'
import InternalLink from '../BlockContent/serializers/InternalLink'

const locale = process.env.GATSBY_LOCALE

const Wrapper = styled(Block)`
  color: ${theme('colors.mediumGray')};
  padding-bottom: ${rem(56)};
`

const StyledLogo = styled(Logo).attrs(() => ({ inverted: true }))`
  width: ${rem(160)};
  margin: 0 auto;
`

const StyledLink = styled.a`
  color: inherit;
`

const StyledSocial = styled(Social)`
  height: ${rem(30)};
`

const FlexWrapper = styled.div`
  margin: ${rem(16)} 0 ${rem(48)};
  ${media('sm')`
    margin-top: 0;
  `}
  display: flex;
  justify-content: center;
  > * {
    margin: 0 ${rem(24)};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
`

const Sitemap = styled.div`
  margin: ${rem(24)} 0;
`

const query = graphql`
  {
    sanityFooter {
      sitemap {
        _id
        title {
          enUS
          svSE
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  return (
    <Wrapper component="footer" type="dark">
      <FlexWrapper>
        <a
          href="https://www.facebook.com/chorusclass/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledSocial type="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/chorusclass/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledSocial type="Instagram" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCqkh2y_2AA3xUf37jouLS-g"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledSocial type="Youtube" />
        </a>
      </FlexWrapper>
      <Sitemap>
        {data.sanityFooter.sitemap.map(item => (
          <StyledLink as={InternalLink} key={item._id} to={item._id}>
            <p>{item.title[locale]}</p>
          </StyledLink>
        ))}
      </Sitemap>
      <StyledLogo />
      <p>© 2024 ChorusClass Sweden AB</p>
      <p>
        <StyledLink as={Link} to="/privacy-policy/">
          Privacy Policy
        </StyledLink>{' '}
        |{' '}
        <StyledLink as={Link} to="/terms/">
          Terms
        </StyledLink>
      </p>
    </Wrapper>
  )
}

export default Footer
