import React from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

const Wrapper = styled.svg`
  display: block;

  > .first {
    fill: ${theme('colors.gold')};
  }

  > .second {
    fill: ${ifProp(
      'inverted',
      theme('colors.white'),
      theme('colors.darkGray')
    )};
  }
`

const Logo = ({ className, ...props }) => (
  <Wrapper
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-177 395.9 240 50"
    space="preserve"
    className={className}
    {...props}
  >
    <g className="first">
      <path
        d="M-147,413c-0.5-0.8-1.1-1.5-1.8-2c-0.7-0.5-1.5-1-2.4-1.2c-0.9-0.3-1.8-0.4-2.8-0.4c-1.7,0-3.3,0.3-4.5,1
      c-1.3,0.7-2.3,1.5-3.1,2.6c-0.8,1.1-1.4,2.3-1.8,3.7c-0.4,1.4-0.6,2.8-0.6,4.2c0,1.4,0.2,2.8,0.6,4.2c0.4,1.4,1,2.6,1.8,3.7
      c0.8,1.1,1.8,2,3.1,2.6c1.3,0.7,2.8,1,4.5,1c1.2,0,2.3-0.2,3.3-0.7c1-0.4,1.8-1,2.5-1.8c0.7-0.8,1.3-1.7,1.7-2.7
      c0.4-1,0.7-2.1,0.8-3.3h2.5c-0.2,1.6-0.6,3.1-1.1,4.4c-0.6,1.3-1.4,2.4-2.3,3.3c-0.9,0.9-2,1.6-3.3,2.1c-1.3,0.5-2.7,0.7-4.2,0.7
      c-2.1,0-3.9-0.4-5.4-1.1c-1.6-0.8-2.9-1.8-3.9-3s-1.8-2.7-2.3-4.4c-0.5-1.6-0.8-3.4-0.8-5.2c0-1.8,0.3-3.6,0.8-5.2
      c0.5-1.6,1.3-3.1,2.3-4.4c1-1.3,2.3-2.3,3.9-3c1.6-0.8,3.4-1.1,5.4-1.1c1.3,0,2.5,0.2,3.7,0.6c1.2,0.4,2.3,0.9,3.2,1.6
      s1.8,1.6,2.4,2.7c0.7,1.1,1.1,2.3,1.2,3.7h-2.5C-146.1,414.7-146.5,413.8-147,413z"
      />
      <path
        d="M-137.3,407.7v10.6h0.1c0.4-1.2,1.2-2.1,2.4-2.8c1.1-0.7,2.4-1.1,3.7-1.1c1.3,0,2.4,0.2,3.3,0.5
      s1.6,0.8,2.2,1.5s0.9,1.4,1.1,2.3c0.2,0.9,0.3,1.9,0.3,3.1v12.3h-2.3v-11.9c0-0.8-0.1-1.6-0.2-2.3c-0.1-0.7-0.4-1.3-0.8-1.8
      c-0.4-0.5-0.9-0.9-1.5-1.2c-0.6-0.3-1.4-0.4-2.3-0.4c-0.9,0-1.8,0.2-2.5,0.5c-0.7,0.3-1.3,0.8-1.8,1.4c-0.5,0.6-0.9,1.3-1.2,2.1
      c-0.3,0.8-0.4,1.7-0.5,2.6v11.1h-2.3v-26.4H-137.3z"
      />
      <path
        d="M-107.9,415.3c1.1,0.5,2.1,1.3,2.8,2.2c0.8,0.9,1.3,2,1.7,3.2c0.4,1.2,0.6,2.5,0.6,3.9s-0.2,2.7-0.6,3.9
      c-0.4,1.2-1,2.3-1.7,3.2c-0.8,0.9-1.7,1.6-2.8,2.2c-1.1,0.5-2.4,0.8-3.9,0.8c-1.5,0-2.7-0.3-3.9-0.8s-2.1-1.2-2.8-2.2
      c-0.8-0.9-1.3-2-1.7-3.2c-0.4-1.2-0.6-2.5-0.6-3.9s0.2-2.7,0.6-3.9c0.4-1.2,1-2.3,1.7-3.2c0.8-0.9,1.7-1.6,2.8-2.2
      c1.1-0.5,2.4-0.8,3.9-0.8C-110.3,414.5-109,414.8-107.9,415.3z M-114.7,417.1c-0.8,0.5-1.5,1.1-2.1,1.8c-0.6,0.8-1,1.6-1.3,2.6
      s-0.4,2-0.4,3s0.1,2,0.4,3s0.7,1.8,1.3,2.6c0.6,0.8,1.2,1.4,2.1,1.8c0.8,0.5,1.8,0.7,2.9,0.7c1.1,0,2.1-0.2,2.9-0.7
      c0.8-0.5,1.5-1.1,2.1-1.8c0.6-0.8,1-1.6,1.3-2.6c0.3-1,0.4-2,0.4-3s-0.1-2-0.4-3c-0.3-1-0.7-1.8-1.3-2.6c-0.6-0.8-1.2-1.4-2.1-1.8
      c-0.8-0.5-1.8-0.7-2.9-0.7C-112.9,416.4-113.8,416.7-114.7,417.1z"
      />
      <path
        d="M-97.1,415v4.5h0.1c0.6-1.6,1.5-2.7,2.8-3.6c1.2-0.8,2.7-1.2,4.5-1.1v2.3c-1.1,0-2,0.1-2.9,0.4
      c-0.9,0.3-1.6,0.8-2.2,1.4s-1.1,1.3-1.5,2.2s-0.5,1.8-0.5,2.8v10.2h-2.3V415H-97.1z"
      />
      <path
        d="M-74.3,434.1v-3.4h-0.1c-0.6,1.3-1.5,2.3-2.7,2.9c-1.2,0.7-2.5,1-3.9,1c-1.2,0-2.2-0.2-3.1-0.5
      c-0.8-0.3-1.5-0.8-2.1-1.4c-0.5-0.6-0.9-1.4-1.2-2.3c-0.2-0.9-0.4-1.9-0.4-3V415h2.3v12.4c0,1.7,0.4,3,1.2,3.9
      c0.8,0.9,2,1.3,3.9,1.3c1,0,1.9-0.2,2.6-0.6c0.7-0.4,1.3-1,1.8-1.7c0.5-0.7,0.8-1.5,1-2.5c0.2-0.9,0.3-1.9,0.3-2.8v-10h2.3v19.1
      H-74.3z"
      />
      <path
        d="M-56.8,418.7c-0.3-0.5-0.6-1-1.1-1.3c-0.5-0.3-1-0.6-1.6-0.8c-0.6-0.2-1.2-0.2-1.9-0.2c-0.5,0-1,0.1-1.6,0.2
      c-0.5,0.1-1,0.3-1.5,0.6c-0.4,0.3-0.8,0.6-1.1,1s-0.4,0.9-0.4,1.6c0,0.5,0.1,1,0.4,1.3c0.3,0.4,0.6,0.7,1,0.9
      c0.4,0.2,0.8,0.5,1.3,0.6c0.5,0.2,0.9,0.3,1.3,0.4l3.1,0.7c0.7,0.1,1.3,0.3,2,0.5c0.7,0.3,1.2,0.6,1.7,1c0.5,0.4,0.9,0.9,1.2,1.6
      c0.3,0.6,0.5,1.3,0.5,2.2c0,1-0.2,1.9-0.7,2.6c-0.5,0.7-1.1,1.3-1.8,1.8s-1.5,0.8-2.5,1c-0.9,0.2-1.8,0.3-2.7,0.3
      c-2.2,0-4.1-0.5-5.4-1.6c-1.4-1.1-2.2-2.8-2.4-5.1h2.3c0.1,1.6,0.7,2.8,1.7,3.6c1,0.8,2.3,1.2,3.9,1.2c0.6,0,1.2-0.1,1.8-0.2
      s1.2-0.3,1.7-0.6c0.5-0.3,0.9-0.7,1.3-1.1c0.3-0.5,0.5-1,0.5-1.7c0-0.6-0.1-1-0.4-1.4c-0.2-0.4-0.5-0.7-0.9-1
      c-0.4-0.3-0.8-0.5-1.3-0.6c-0.5-0.2-1-0.3-1.5-0.4l-3-0.7c-0.8-0.2-1.5-0.4-2.1-0.7c-0.6-0.3-1.2-0.6-1.7-1s-0.9-0.8-1.1-1.4
      c-0.3-0.6-0.4-1.2-0.4-2c0-1,0.2-1.8,0.6-2.5c0.4-0.7,1-1.2,1.7-1.7c0.7-0.4,1.5-0.7,2.3-0.9s1.7-0.3,2.5-0.3c1,0,1.9,0.1,2.7,0.4
      c0.8,0.2,1.6,0.6,2.2,1.1c0.6,0.5,1.1,1.2,1.5,1.9c0.4,0.8,0.6,1.7,0.6,2.7h-2.3C-56.4,419.9-56.6,419.3-56.8,418.7z"
      />
    </g>
    <g className="second">
      <path
        d="M-32.8,414.8c-0.3-0.6-0.8-1-1.3-1.5s-1.1-0.7-1.8-1c-0.7-0.2-1.3-0.4-2.1-0.4c-1.3,0-2.4,0.3-3.3,0.8
      c-0.9,0.5-1.6,1.2-2.2,2c-0.6,0.8-1,1.8-1.2,2.9c-0.3,1.1-0.4,2.2-0.4,3.4c0,1.1,0.1,2.2,0.4,3.2c0.3,1,0.7,2,1.2,2.8
      c0.6,0.8,1.3,1.5,2.2,2c0.9,0.5,2,0.8,3.3,0.8c1.8,0,3.2-0.5,4.2-1.6c1-1.1,1.6-2.5,1.8-4.3h5.6c-0.1,1.6-0.5,3.1-1.1,4.5
      c-0.6,1.3-1.4,2.5-2.4,3.4c-1,0.9-2.2,1.7-3.5,2.1s-2.8,0.7-4.5,0.7c-2,0-3.8-0.4-5.4-1.1c-1.6-0.7-3-1.7-4.1-2.9
      c-1.1-1.2-2-2.7-2.5-4.3c-0.6-1.7-0.9-3.5-0.9-5.4c0-2,0.3-3.8,0.9-5.5c0.6-1.7,1.4-3.2,2.5-4.4c1.1-1.3,2.5-2.2,4.1-3
      c1.6-0.7,3.4-1.1,5.4-1.1c1.5,0,2.8,0.2,4.1,0.6s2.4,1,3.5,1.8c1,0.8,1.9,1.8,2.5,3c0.7,1.2,1.1,2.5,1.3,4.1h-5.6
      C-32.2,415.9-32.4,415.3-32.8,414.8z"
      />
      <path d="M-17.5,407.7v26.4h-5.2v-26.4H-17.5z" />
      <path
        d="M-13.6,420.9c0.1-1.2,0.4-2.3,0.9-3.1s1.2-1.5,2.1-2s1.8-0.8,2.8-1.1s2.1-0.3,3.2-0.3c1,0,1.9,0.1,2.9,0.2
      c1,0.1,1.9,0.4,2.7,0.8c0.8,0.4,1.5,0.9,2,1.6c0.5,0.7,0.8,1.6,0.8,2.8v9.9c0,0.9,0,1.7,0.1,2.5c0.1,0.8,0.3,1.4,0.5,1.8h-5.3
      c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.1-0.6-0.1-0.9c-0.8,0.9-1.8,1.5-3,1.8s-2.3,0.5-3.5,0.5c-0.9,0-1.8-0.1-2.5-0.3
      c-0.8-0.2-1.5-0.6-2.1-1c-0.6-0.5-1.1-1.1-1.4-1.8s-0.5-1.6-0.5-2.5c0-1.1,0.2-2,0.6-2.7c0.4-0.7,0.9-1.3,1.5-1.7
      c0.6-0.4,1.3-0.7,2.1-0.9s1.6-0.4,2.3-0.5s1.6-0.2,2.3-0.3c0.8-0.1,1.4-0.2,2-0.3c0.6-0.1,1.1-0.4,1.4-0.6
      c0.3-0.3,0.5-0.7,0.5-1.2c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.4-0.6-0.7-0.8s-0.7-0.3-1.1-0.4s-0.8-0.1-1.3-0.1c-1,0-1.8,0.2-2.4,0.7
      c-0.6,0.4-0.9,1.2-1,2.2H-13.6z M-1.5,424.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.8,0.1-1.2,0.2
      c-0.4,0-0.8,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.7,0.3-1,0.5c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.3,0.7-0.3,1.2
      c0,0.5,0.1,0.9,0.3,1.2c0.2,0.3,0.4,0.6,0.7,0.8c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.8,0.1,1.2,0.1c1,0,1.8-0.2,2.4-0.5
      s1-0.8,1.3-1.2s0.4-1,0.5-1.5c0.1-0.5,0.1-0.9,0.1-1.2V424.7z"
      />
      <path
        d="M12.3,429.3c0.2,0.4,0.5,0.7,0.9,1s0.8,0.4,1.3,0.6c0.5,0.1,1,0.2,1.5,0.2c0.4,0,0.8,0,1.2-0.1
      c0.4-0.1,0.8-0.2,1.1-0.4s0.6-0.4,0.8-0.7s0.3-0.7,0.3-1.2c0-0.8-0.5-1.4-1.6-1.8c-1-0.4-2.5-0.8-4.4-1.2
      c-0.8-0.2-1.5-0.4-2.2-0.6c-0.7-0.2-1.4-0.5-1.9-0.9s-1-0.9-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1c0-1.2,0.2-2.2,0.7-3
      c0.5-0.8,1.1-1.4,1.9-1.9c0.8-0.5,1.7-0.8,2.7-1c1-0.2,2-0.3,3-0.3c1,0,2,0.1,3,0.3c1,0.2,1.8,0.5,2.6,1c0.8,0.5,1.4,1.1,1.9,1.9
      s0.8,1.8,0.9,2.9h-5c-0.1-1-0.5-1.7-1.1-2c-0.7-0.4-1.5-0.5-2.4-0.5c-0.3,0-0.6,0-1,0.1c-0.3,0-0.7,0.1-0.9,0.2
      c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.5,0.2,0.8,0.5,1.1c0.3,0.3,0.8,0.5,1.3,0.7s1.2,0.4,1.9,0.5
      c0.7,0.1,1.4,0.3,2.2,0.5c0.8,0.2,1.5,0.4,2.2,0.6c0.7,0.2,1.4,0.6,1.9,1c0.6,0.4,1,0.9,1.4,1.5c0.3,0.6,0.5,1.3,0.5,2.2
      c0,1.3-0.3,2.3-0.8,3.2c-0.5,0.8-1.2,1.5-2,2c-0.8,0.5-1.7,0.9-2.8,1.1c-1,0.2-2.1,0.3-3.2,0.3c-1.1,0-2.2-0.1-3.2-0.3
      c-1.1-0.2-2-0.6-2.8-1.1c-0.8-0.5-1.5-1.2-2-2.1c-0.5-0.8-0.8-1.9-0.9-3.2h5C11.9,428.5,12,428.9,12.3,429.3z"
      />
      <path
        d="M32.1,429.3c0.2,0.4,0.5,0.7,0.9,1s0.8,0.4,1.3,0.6c0.5,0.1,1,0.2,1.5,0.2c0.4,0,0.8,0,1.2-0.1
      c0.4-0.1,0.8-0.2,1.1-0.4s0.6-0.4,0.8-0.7s0.3-0.7,0.3-1.2c0-0.8-0.5-1.4-1.6-1.8c-1-0.4-2.5-0.8-4.4-1.2
      c-0.8-0.2-1.5-0.4-2.2-0.6c-0.7-0.2-1.4-0.5-1.9-0.9s-1-0.9-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1c0-1.2,0.2-2.2,0.7-3
      c0.5-0.8,1.1-1.4,1.9-1.9c0.8-0.5,1.7-0.8,2.7-1c1-0.2,2-0.3,3-0.3c1,0,2,0.1,3,0.3c1,0.2,1.8,0.5,2.6,1c0.8,0.5,1.4,1.1,1.9,1.9
      s0.8,1.8,0.9,2.9h-5c-0.1-1-0.5-1.7-1.1-2c-0.7-0.4-1.5-0.5-2.4-0.5c-0.3,0-0.6,0-1,0.1c-0.3,0-0.7,0.1-0.9,0.2
      c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.5,0.2,0.8,0.5,1.1c0.3,0.3,0.8,0.5,1.3,0.7s1.2,0.4,1.9,0.5
      c0.7,0.1,1.4,0.3,2.2,0.5c0.8,0.2,1.5,0.4,2.2,0.6c0.7,0.2,1.4,0.6,1.9,1c0.6,0.4,1,0.9,1.4,1.5c0.3,0.6,0.5,1.3,0.5,2.2
      c0,1.3-0.3,2.3-0.8,3.2c-0.5,0.8-1.2,1.5-2,2c-0.8,0.5-1.7,0.9-2.8,1.1c-1,0.2-2.1,0.3-3.2,0.3c-1.1,0-2.2-0.1-3.2-0.3
      c-1.1-0.2-2-0.6-2.8-1.1c-0.8-0.5-1.5-1.2-2-2.1c-0.5-0.8-0.8-1.9-0.9-3.2h5C31.7,428.5,31.9,428.9,32.1,429.3z"
      />
    </g>
  </Wrapper>
)

export default Logo
