import React, { Fragment } from 'react'
import styled from 'styled-components'

const locale = process.env.GATSBY_LOCALE

const crossCountryLinks = [
  {
    locale: 'svSE',
    href: 'https://chorusclass.se',
    label: 'Svenska',
  },
  {
    locale: 'enUS',
    href: 'https://chorusclass.com',
    label: 'English',
  },
]

const Wrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
`

const CrossCountry = ({ className }) => (
  <Wrapper className={className}>
    {crossCountryLinks
      .filter(country => country.locale !== locale)
      .map((country, idx) => (
        <Fragment key={country.label}>
          {idx > 0 && <span> | </span>}
          <a href={country.href}>{country.label}</a>
        </Fragment>
      ))}
  </Wrapper>
)

export default CrossCountry
