import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import { media } from '../../theme'
import Block from '../Block'
import Logo from '../Logo'
import Hamburger from '../Hamburger'
import Menu from '../Menu'

import CrossCountry from './CrossCountry'

const StyledHeader = styled(Block)`
  position: relative;
  z-index: 3;
  padding: ${rem(20)};
`

const StyledLogo = styled(Logo)`
  height: ${rem(50)};
  ${media('sm')`
    height: ${rem(60)};
  `}
  max-width: 100%;
  margin: 0 auto;
  ${media('sm')`
    margin: 0;
  `}
`

const StyledHamburger = styled(Hamburger)`
  position: absolute;
  z-index: 1;
  top: ${rem(8)};
  right: ${rem(0)};
  padding: 12px;
  width: 45px;
  ${media('sm')`
    display: none;
  `}
`

const StyledCrossCountry = styled(CrossCountry)`
  display: none;
  padding: ${rem(21)};
  ${media('sm')`
    display: block;
  `}
`

const Header = ({ siteTitle, pathname }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <StyledHeader component="header" type="dark">
        <Link to="/">
          <StyledLogo inverted />
        </Link>
        <StyledHamburger
          open={menuOpen}
          onClick={() => setMenuOpen(open => !open)}
        />
        <Menu open={menuOpen} pathname={pathname} />
      </StyledHeader>
      <StyledCrossCountry />
    </>
  )
}

export default Header
