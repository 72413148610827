import { rem, tint, transparentize } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  gold: '#CCA25C',
  steel: '#5E6377',
  blue: '#0674C6',
  green: '#00AD6B',
  red: '#EB2638',
  lightGray: '#E3E3E3',
  mediumGray: '#BBBBBB',
  darkGray: '#303030',
}

const contextualColors = {
  background: tint(0.9, colors.black),
  primary: colors.gold,
  secondary: colors.steel,
  dark: colors.darkGray,
  success: colors.green,
  warning: colors.red,
  plus: colors.blue,
  pro: colors.green,
  bundle: colors.gold,
  primaryText: tint(0.2, colors.black),
  glass: transparentize(0.4, colors.steel),
}

export default {
  typography: {
    fontFamily: `Helvetica Neue, sans-serif`,
    fontSize: rem(14),
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  gutterWidth: '16px',
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  colors: {
    ...colors,
    ...contextualColors,
  },
}
