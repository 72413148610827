import styled, { css } from 'styled-components'
import { ifProp, prop, withProp, theme } from 'styled-tools'
import { media } from '../../theme'

const containerSize = size =>
  media(size)`
    max-width: ${prop(size, theme(`sizes.${size}`))}
  `

const getSize = (value = 1) => css`
  width: ${value * 100}%;
`
const columnSize = size => ifProp(size, media(size)(withProp(size, getSize)))

const Column = styled.div`
  ${withProp('size', getSize)};
  ${withProp('theme.breakpoints', breakpoints =>
    Object.keys(breakpoints).map(columnSize)
  )}
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${withProp('theme.breakpoints', breakpoints =>
    Object.keys(breakpoints).map(containerSize)
  )}

  & > ${Row} {
    margin-left: -${prop('gutterWidth', theme('gutterWidth'))};
    margin-right: -${prop('gutterWidth', theme('gutterWidth'))};
    & > ${Column} {
      padding-left: ${prop('gutterWidth', theme('gutterWidth'))};
      padding-right: ${prop('gutterWidth', theme('gutterWidth'))};
    }
  }
`

export { Container, Row, Column }
