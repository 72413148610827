import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const locale = process.env.GATSBY_LOCALE

const query = graphql`
  {
    pages: allSanityPage {
      nodes {
        _id
        slug {
          enUS {
            current
          }
          svSE {
            current
          }
        }
      }
    }
  }
`

const InternalLink = ({ to, ...props }) => {
  const data = useStaticQuery(query)
  const page = data.pages.nodes.find(page => page._id === to)
  return page ? (
    <Link to={`/${page.slug[locale].current}/`} {...props} />
  ) : (
    props.children
  )
}

export default InternalLink
