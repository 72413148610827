import React from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

const Wrapper = styled.div`
  font-size: 18px;
  width: 21px;
  cursor: pointer;
  > div {
    background: ${theme('colors.primary')};
    height: 3px;
    margin-bottom: 4px;
    &:nth-child(1) {
      transition: transform 150ms linear;
      transform: ${ifProp(
        'open',
        `translateY(${700 / 3}%) rotate(-45deg)`,
        ''
      )};
    }
    &:nth-child(2) {
      transition: opacity 150ms linear;
      opacity: ${ifProp('open', 0, 1)};
    }
    &:nth-child(3) {
      transition: transform 150ms linear;
      transform: ${ifProp(
        'open',
        `translateY(-${700 / 3}%) rotate(45deg)`,
        ''
      )};
      margin-bottom: 0;
    }
  }
`

const Hamburger = ({ open, className, onClick }) => (
  <Wrapper open={open} className={className} onClick={onClick}>
    <div />
    <div />
    <div />
  </Wrapper>
)

export default Hamburger
